<template>
  <div id="chef-view">
    <v-row>
      <v-col cols="12">
        <chef-bio-panel
          :chef-data="chefData"
          :kitchenList="kitchenList"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
          @refresh="udpateChefData"
        ></chef-bio-panel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline } from '@mdi/js'
import chefStoreModule from '../chefStoreModule'
import ChefBioPanel from './chef-bio-panel/ChefBioPanel.vue'

export default {
  components: {
    ChefBioPanel,
  },
  methods: {
    udpateChefData(value) {
      this.chefData = value
    },
  },
  setup() {
    const CHEF_APP_STORE_MODULE_NAME = 'app-chef'

    // Register module
    if (!store.hasModule(CHEF_APP_STORE_MODULE_NAME)) store.registerModule(CHEF_APP_STORE_MODULE_NAME, chefStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHEF_APP_STORE_MODULE_NAME)) store.unregisterModule(CHEF_APP_STORE_MODULE_NAME)
    })

    const chefData = ref({})
    const chefTab = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)
    const kitchenList = ref([])

    store
      .dispatch('app-chef/fetchChef', { id: router.currentRoute.params.id })
      .then(response => {
        chefData.value = response.data.data
        selectedPlan.value = response.data.currentPlan
        console.log('chefData.value', chefData.value)
      })
      .catch(error => {
        if (error.response.status === 404) {
          chefData.value = {}
        }
      })
      store
          .dispatch('app-chef/kitchensList')
          .then(response => {
            console.log('kitchensList',response.data.data.filteredData);
            kitchenList.value = response.data.data.filteredData
          }).catch(error=>{
            console.log('error',error)
          })

    const tabs = [{ icon: mdiAccountOutline, title: 'Overview' }]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      kitchenList,
      tabs,
      chefTab,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      chefData,
      resolveCurrentPlanValue,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
