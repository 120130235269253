<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="chef-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Edit Chef Information </v-card-title>
      <v-card-text class="text-center mt-n2"> Updating Chef details will receive a privacy audit. </v-card-text>

      <v-card-text class="mt-5">
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="chefDataLocal.name"
                outlined
                dense
                label="Chef Name"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <!-- <v-text-field
                v-model="chefDataLocal.mobile_number"
                outlined
                dense
                :rules="[validators.required, validators.integerValidator]"
                label="Mobile Number"
                type="number"
                hide-spin-buttons
              ></v-text-field> -->
              <vue-tel-input-vuetify
                @onInput="onInput_contact_number"
                v-model="chefDataLocal.mobile_number"
                defaultCountry="JO"
                :onlyCountries="['JO', 'SA']"
                @keypress="isNumber($event)"
                @paste.prevent
                :rules="[validators.validtelfun(chefDataLocal.mobile_number, validtel)]"
                :maxLen="18"
                label="Mobile Number"
                placeholder="+96279854****"
                class="uppercase"
              ></vue-tel-input-vuetify>
            </v-col>

            <v-col cols="12" sm="6">
              <!-- <v-text-field
                v-model="chefDataLocal.emergency_number"
                outlined
                dense
                :rules="[validators.required, validators.integerValidator]"
                label="Emergency Number"
                type="number"
                hide-spin-buttons
              ></v-text-field> -->
              <vue-tel-input-vuetify
                @onInput="onInput_emergency_number"
                v-model="chefDataLocal.emergency_number"
                defaultCountry="JO"
                :onlyCountries="['JO', 'SA']"
                @keypress="isNumber($event)"
                @paste.prevent
                :rules="[validators.validtelfun(chefDataLocal.emergency_number, validtel2)]"
                :maxLen="18"
                label="Emergency Number"
                placeholder="+96279854****"
                class="uppercase"
              ></vue-tel-input-vuetify>
            </v-col>

            <v-col cols="12" sm="6">
              <v-row class="mb-6" v-if="showImage">
                <v-file-input
                  class="file"
                  v-model="chefDataLocal.image"
                  :rules="[validators.required, fileAccept(['jpg', 'jpeg', 'png'], chefDataLocal.image)]"
                  :prepend-icon="icons.mdiCamera"
                  accept=".jpg, .jpeg, .png"
                  label="Upload Image (.jpg, .jpeg, .png)"
                  hint="Image dimensions: min-width: 126px, max-width: 252px, min-height: 95px, max-height: 189px, max-file-size: 500kb"
                  persistent-hint
                  outlined
                  dense
                >
                </v-file-input>
              </v-row>
              <v-row class="review-file" v-if="!showImage">
                <p class="uppercase">Upload Image File</p>
                <div class="review-file__btns">
                  <v-btn x-small color="primary" dark @click="openPdf(chefDataLocal.image)"> Review File </v-btn>
                  <v-btn x-small color="warning" dark @click="editImageFileSizes()"> Edit File </v-btn>
                </div>
              </v-row>
              <v-card-text style="color: #ff3342" v-if="message">
                {{ message }}
              </v-card-text>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                readonly
                disabled
                v-model="chefDataLocal.kitchen_id"
                :rules="[validators.required]"
                placeholder="Kitchen"
                label="Kitchen"
                :items="kitchenList"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> submit </v-btn>

              <v-btn outlined color="secondary" @click.prevent="$emit('update:is-bio-dialog-open', false)">
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { emailValidator, imageValidator, integerValidator, required, validtelfun } from '@core/utils/validation'
import { ref, watch } from '@vue/composition-api'
import Swal from 'sweetalert2'
import { isString } from '@vueuse/shared'
import { mdiCamera } from '@mdi/js'

export default {
  data() {
    return {
      showHint: true,
    }
  },
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    chefData: {
      type: Object,
      required: true,
    },
    kitchenList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    hideHint() {
      this.showHint = false
    },
  },
  setup(props, { emit }) {
    const isBillingAddress = ref(true)
    const message = ref('')

    const chefDataLocal = ref({})

    chefDataLocal.value = JSON.parse(JSON.stringify(props.chefData))
    const validtel = ref(false)
    const validtel2 = ref(false)
    const showImage = ref(false)

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const resetchefData = () => {
      resetForm()
      chefDataLocal.value = JSON.parse(JSON.stringify(chefDataLocal.value))
    }

    const openPdf = file => {
      console.log('file', file)
      window.open(file)
    }

    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]
    const languageOptions = ['English', 'Spanish', 'Portuguese', 'Russian', 'French', 'German']
    const onInput_contact_number = ({ number, isValid }) => {
      if (number?.e164) {
        chefDataLocal.value.mobile_number = number.e164
        validtel.value = isValid
      } else {
        validtel.value = false
      }
      let tester = /^(\+\d{0,}\s?\d{0,}\s?\d{0,}\s?\d{0,})?$/.test(number.input)
      let tester2 = /^(\+\d{0,}\s?\d{0,}[-]?\d{0,}[-]?\d{0,})?$/.test(number.input)
      if (!tester && !tester2) {
        validtel.value = false
      }
    }

    const onInput_emergency_number = ({ number, isValid }) => {
      if (number?.e164) {
        chefDataLocal.value.emergency_number = number.e164
        validtel2.value = isValid
      } else {
        validtel2.value = false
      }
      let tester = /^(\+\d{0,}\s?\d{0,}\s?\d{0,}\s?\d{0,})?$/.test(number.input)
      let tester2 = /^(\+\d{0,}\s?\d{0,}[-]?\d{0,}[-]?\d{0,})?$/.test(number.input)
      if (!tester && !tester2) {
        validtel2.value = false
      }
    }
    // on form submit
    const onSubmit = async () => {
      if (valid.value) {
        try {
          const data = await store.dispatch('app-chef/editChef', chefDataLocal.value)
          const responseData = data.data.data.chief
          console.log('responseData', responseData)
          emit('refresh', responseData)
          emit('update:is-bio-dialog-open', false)
        } catch (error) {
          Object.entries(error.data.message).forEach(err => {
            Swal.fire({
              icon: 'error',
              title: `${err[0]}`,
              text: `${err[1][0]}`,
              footer:
                'Recommended image dimensions: min-width: 126px; max-width: 252px; min-height: 95px; max-height: 189px; max-file-size: 500kb',
            })
          })
        }
      } else {
        validate()
      }
    }
    const fileAccept = (accept, data) => {
      if (data && !isString(data)) {
        if (data.size > 500000) {
          return `MAX SIZE 500KB`
        }
        if (accept.includes(data.name.split('.')[1])) {
          return true
        } else {
          return `Please choose file path (${accept})`
        }
      }
      return true
    }
    const editImageFileSizes = () => {
      showImage.value = true
      chefDataLocal.value.image = null
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        showImage.value = false
        chefDataLocal.value = JSON.parse(JSON.stringify(props.chefData))
        chefDataLocal.value.kitchen_id = parseInt(chefDataLocal.value.kitchen_id)
        if (chefDataLocal.value.emergency_number) {
          validtel2.value = true
        }
        if (chefDataLocal.value.mobile_number) {
          validtel.value = true
        }
      },
    )

    return {
      validtel2,
      validtel,
      onInput_contact_number,
      onInput_emergency_number,
      editImageFileSizes,
      showImage,
      openPdf,
      message,
      fileAccept,
      isBillingAddress,
      statusOptions,
      languageOptions,
      countries,
      chefDataLocal,
      resetchefData,
      form,
      valid,
      validate,
      onSubmit,
      icons: {
        mdiCamera,
      },
      // validation
      validators: {
        required,
        emailValidator,
        integerValidator,
        validtelfun,
        imageValidator,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.review-file {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &__btns {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1.4rem;
  }
}
</style>
