<template>
  <v-row class="chef-bio-panel">
    <!-- chef profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="chefData.avatar ? '' : 'primary'"
            :class="chefData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img :src="chefData.image"></v-img>
          </v-avatar>
          <span class="mb-2">{{ chefData.name }}</span>
        </v-card-title>

        <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
          <div class="d-flex align-center me-8 mb-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              style="width: fit-content"
              class="v-avatar-light-bg primary--text me-3"
            >
              <div>
                <h3 class="text-xl font-weight-medium mb-n1">{{ chefData.rate }}</h3>
              </div>
              <v-icon color="primary" size="22">
                {{ icons.mdiStarOutline }}
              </v-icon>
            </v-avatar>
          </div>
        </v-card-text>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Chef Name:</span>
              <span class="text--secondary">{{ chefData.name }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Mobile Number:</span>
              <span class="text--secondary">{{ chefData.mobile_number }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Emergency Number:</span>
              <span class="text--secondary">{{ chefData.emergency_number }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">kitchen Name:</span>
              <span class="text--secondary">{{ chefData.kitchen_name }}</span>
            </v-list-item>

            <!-- table -->
            <v-data-table id="table" :headers="tableColumnsForItems" :items="chefData.items" class="mt-10">
              <template v-slot:top>
                <v-toolbar rounded short dense color="rgb(104 82 215 / 28%)" flat>
                  <v-toolbar-title class="font-weight-medium me-2 text-capitalize">Items Table</v-toolbar-title>
                </v-toolbar>
              </template>

              <template #[`item.name_en`]="{ item }">
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <router-link
                      :to="{ name: 'apps-item-view', params: { id: item.id } }"
                      class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                    >
                      {{ item.name_en }}
                    </router-link>
                  </div>
                </div>
              </template>

              <!-- kitchen_name -->
              <template #[`item.kitchen_name`]="{ item }">
                <div class="d-flex align-center">
                  <div class="d-flex flex-column">
                    {{ item.kitchen_name ? item.kitchen_name : '___' }}
                  </div>
                </div>
              </template>

              <!-- type -->
              <template #[`item.type`]="{ item }">
                <div class="d-flex align-center">
                  <span class="text-capitalize">{{
                    item.type == 1
                      ? 'Meal'
                      : item.type == 2
                      ? 'Pre-Order'
                      : item.type == 'Soft-drink , Add-ons'
                      ? 'Meal'
                      : '___'
                  }}</span>
                </div>
              </template>
            </v-data-table>
            <br />

            <v-card-actions class="justify-center pt-5">
              <v-btn color="primary" class="me-3" @click="isBioDialogOpen = !isBioDialogOpen"> Edit </v-btn>
              <v-btn color="error" outlined @click="handleDelete(chefData.id)"> Delete </v-btn>
            </v-card-actions>
          </v-list>
        </v-card-text>
      </v-card>

      <!-- edit profile dialog data -->
      <chef-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :chef-data="chefData"
        :kitchenList="kitchenList"
        @refresh="updateChefData"
      ></chef-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { avatarText, kFormatter } from '@core/utils/filter'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle, mdiStarOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import useChefsList from '../../chef-list/useChefsList'
import ChefBioEdit from './ChefBioEdit.vue'

export default {
  components: {
    ChefBioEdit,
  },
  props: {
    chefData: {
      type: Object,
      required: true,
    },
    kitchenList: {
      type: Array,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updateChefData(newValue) {
      this.$emit('refresh', newValue)
    },
  },
  setup() {
    const { tableColumnsForItems, resolveChefStatusVariant, resolveChefRoleVariant } = useChefsList()

    const isBioDialogOpen = ref(false)

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    const handleDelete = id => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-chef/deleteChef', id)
            .then(() => {
              Swal.fire('Deleted!', 'entry has been deleted.', 'success')
              router.push({ name: 'apps-chef-list' })
            })
            .catch(response => {
              console.error(response)
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.message,
                footer: 'This Chef might have items that needs to be deleted first',
              })
            })
        }
      })
    }

    return {
      tableColumnsForItems,
      resolveChefStatusVariant,
      resolveChefRoleVariant,
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,
      handleDelete,

      isBioDialogOpen,
      standardPlan,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiStarOutline,
      },
    }
  },
}
</script>
